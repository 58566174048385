import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="count-checkboxes"
export default class extends Controller<HTMLElement> {
  static targets = ["total", "checkbox", "submitButton"];

  declare totalTarget: HTMLSpanElement;
  declare hasTotalTarget: boolean;
  declare checkboxTargets: HTMLInputElement[];
  declare hasCheckboxTarget: boolean;
  declare submitButtonTarget: HTMLInputElement;
  declare hasSubmitButtonTarget: boolean;

  connect() {
    this.count();
  }

  count() {
    if (this.hasTotalTarget) {
      this.totalTarget.innerText = this.getSelectedCheckboxes().length.toString();
    }
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = this.getSelectedCheckboxes().length == 0;
      this.submitButtonTarget.className = this.submitButtonTarget.disabled
        ? "block rounded-md bg-gray-300 px-3 py-2 text-center text-sm font-semibold text-gray-600 shadow-sm cursor-not-allowed opacity-50"
        : "block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 hover:cursor-pointer";
    }
  }

  getSelectedCheckboxes(): HTMLInputElement[] {
    if (this.hasCheckboxTarget) {
      return this.checkboxTargets.filter((c) => c.checked);
    }
    return [];
  }
}
