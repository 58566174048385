import { Controller } from "@hotwired/stimulus";
import L from "leaflet";
import "leaflet-routing-machine";

let map: any;
let icon: any;
let mainIcon: any;

// Connects to data-controller="leaflet"
export default class extends Controller {
  static values = {
    eventCoord: Array,
    eventId: Number,
    eventsIds: Array,
    routes: Array,
    routesRequests: Array,
    zoom: Number,
  };

  declare eventCoordValue: any[];
  declare hasEventCoordValue: boolean;
  declare eventIdValue: number;
  declare hasEventIdValue: boolean;
  declare eventsIdsValue: number[];
  declare hasEventsIdsValue: boolean;
  declare routesValue: any[];
  declare hasRoutesValue: boolean;
  declare routesRequestsValue: any[];
  declare hasRoutesRequestsValue: boolean;
  declare zoomValue: number;
  declare hasZoomValue: boolean;

  async connect() {
    map = this.hasEventCoordValue
      ? new L.Map("map", {
          center: new L.LatLng(this.eventCoordValue[0], this.eventCoordValue[1]),
          zoom: 9,
          zoomControl: true,
          scrollWheelZoom: false,
          attributionControl: false,
        })
      : new L.Map("map", {
          center: new L.LatLng(46.4947387, 2.6028326), // coordonnées de Paris
          zoom: this.hasZoomValue ? this.zoomValue : 6,
          zoomControl: true,
          scrollWheelZoom: false,
          attributionControl: false,
        });

    icon = L.icon({
      iconUrl: "/marker-icon.png",
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
    });

    mainIcon = L.icon({
      iconUrl: "/main_icon.png",
      iconSize: [25, 41],
      iconAnchor: [12.5, 41],
    });

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      subdomains: ["a", "b", "c"],
    }).addTo(map);

    if (this.hasEventIdValue) {
      this.addEvent([this.eventIdValue], true);
    }

    if (this.hasRoutesValue) {
      this.addRoute(this.routesValue);
    } else if (this.hasRoutesRequestsValue) {
      this.addRoute(this.routesRequestsValue, "routeRequest");
    } else if (this.hasEventsIdsValue) {
      await this.addEvent(this.eventsIdsValue);
    }

    map.invalidateSize();
  }

  async addEvent(ids, main = false) {
    const params = {
      events_ids: ids,
      link_to_event: !this.hasRoutesRequestsValue && !this.hasRoutesValue,
    };
    fetch(`/events/ajax_geojson`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        if (json != "error") {
          L.geoJson(json, {
            pointToLayer: function (feature, latLng) {
              let marker = createMarker(latLng, main);
              if (feature.popupContent) {
                marker.bindPopup(feature.popupContent);
              }
              return marker;
            },
          }).addTo(map);
          map.invalidateSize();
        }
      });
  }

  async addRoute(ids, type = "route") {
    const params = {
      event_id: this.eventIdValue,
      routes_id: ids,
      link_to_route: ids.length > 1,
    };
    fetch(type == "route" ? "/carpooling/routes/ajax_geojson" : "/carpooling/route_requests/ajax_geojson", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((json) => {
        if (json != "error") {
          let eventLatLng = this.eventCoordValue;
          json.forEach((route) => {
            let waypoints: any[] = [];
            route.waypoints.forEach((wp) => waypoints.push(L.latLng(wp[0], wp[1])));
            L.Routing.control({
              // waypoints: [L.latLng(44.8333, -0.5667), L.latLng(48.085329, -1.696846)],
              waypoints: waypoints,
              routeWhileDragging: true,
              showAlternatives: false,
              lineOptions: {
                styles: [{ color: "#2564eb", opacity: 1, weight: 3 }],
              },
              formatter: new L.Routing.Formatter({ language: "fr" }),
              // cache les instructions de trajet
              containerClassName: "hidden",
              createMarker: function (i, wp, nWps) {
                if (wp.latLng.lat != eventLatLng[0] && wp.latLng.lng != eventLatLng[1]) {
                  return createMarker(wp.latLng, false).bindPopup(route.steps[i].popupContent);
                }
              },
            }).addTo(map);
          });
        }
      });
  }
}

function createMarker(latlgt, main = false) {
  return L.marker(latlgt, {
    icon: main ? mainIcon : icon,
    riseOnHover: true,
    zIndexOffset: main ? 2000 : 200,
    riseOffset: 5000,
  });
}
