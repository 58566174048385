import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="registration-edit"
export default class extends Controller {
  static targets = ["inputImproperReason", "formImproper", "selectState", "selectSubmitBtn", "apiInfos"];
  static values = { state: String };

  declare inputImproperReasonTarget: HTMLInputElement;
  declare hasInputImproperReasonTarget: boolean;
  declare formImproperTarget: HTMLDivElement;
  declare hasFormImproperTarget: boolean;
  declare stateValue: String;
  declare hasStateValue: boolean;
  declare selectStateTarget: HTMLInputElement;
  declare hasSelectStateTarget: boolean;
  declare selectSubmitBtnTarget: HTMLInputElement;
  declare hasSelectSubmitBtnTarget: boolean;
  connect() {
    if (this.stateValue == "improper") {
      this.displayFormImproper();
    } else {
      this.hideFormImproper();
    }
    this.changeDisabled();
  }

  displayFormImproper() {
    if (this.hasFormImproperTarget && this.formImproperTarget.classList.contains("hidden")) {
      this.formImproperTarget.classList.remove("hidden");
      this.inputImproperReasonTarget.required = true;
    }
  }

  hideFormImproper() {
    if (this.hasFormImproperTarget && !this.formImproperTarget.classList.contains("hidden")) {
      this.formImproperTarget.classList.add("hidden");
      this.inputImproperReasonTarget.required = false;
    }
  }

  toggleFormImproper() {
    this.formImproperTarget.classList.contains("hidden") ? this.displayFormImproper() : this.hideFormImproper();
  }

  changeState() {
    if (this.hasSelectStateTarget) {
      if (this.selectStateTarget.value == "improper") {
        this.displayFormImproper();
      } else {
        this.hideFormImproper();
      }
      this.changeDisabled();
    }
  }

  changeDisabled() {
    if (this.hasSelectSubmitBtnTarget && this.hasSelectStateTarget) {
      this.selectSubmitBtnTarget.disabled =
        this.selectStateTarget.value == "improper" || this.selectStateTarget.value == "";
      this.selectSubmitBtnTarget.className = this.selectSubmitBtnTarget.disabled
        ? "mt-4 block rounded-md bg-gray-300 px-3 py-2 text-center text-sm font-semibold text-gray-600 shadow-sm cursor-not-allowed opacity-50"
        : "mt-4 block rounded-md bg-sky-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-600";
    }
  }
}
